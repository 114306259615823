import React from "react";

import { SessionType } from "@/autoGeneratedGlobalTypes";
import AppointmentLayout from "@/components/appointment/appointmentLayout";
import { isBrowser } from "@/utils/env";

import "../common.scss";

interface AppointmentPageProps {
   location: {
    state: null | {
      redirectToPaymentFlag : boolean
    }
  };
}

const AppointmentPage = ({ location } :AppointmentPageProps) => {
  if (!isBrowser()) {
    return null;
  }

  const urlParams = new URLSearchParams(window.location.search.replace("?", ""));
  const isSuccess = !!urlParams.get("success");
  const isFailure = !!urlParams.get("failure" || "fail");
  const expertId = parseInt(urlParams.get("expert_id") ?? "", 10) ?? undefined;
  const sessionTypeString: string = urlParams.get("session_type") ?? SessionType[SessionType.VIDEO_CALL];
  const sessionType: SessionType = SessionType[sessionTypeString as keyof typeof SessionType];
  const redirectToPaymentFlag = location?.state?.redirectToPaymentFlag;
  const isTrialAllowed = urlParams.get("is_trial_allowed") === "true";

  // todo: Use Helmet
  // let pageTitle = "Результат платежа";
  // if (isSuccess) {
  //   pageTitle = "Платёж прошёл успешно";
  // } else if (isFailure) {
  //   pageTitle = "Платёж отклонён";
  // }

  return (
    <AppointmentLayout
      expertId={expertId}
      isSuccess={isSuccess}
      isFailure={isFailure}
      sessionType={sessionType}
      redirectToPaymentFlag={redirectToPaymentFlag}
      isTrialAllowed={isTrialAllowed}
    />
  );
};

export default AppointmentPage;
