import React from "react";

const UnknownError = () =>
  (
    <>
      Ничего страшного, такое бывает
      <br />
      <br />
      Отправьте заявку снова
      <br />
      или напишите в&nbsp;Службу поддержки
    </>
  );

export default UnknownError;
