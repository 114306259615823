import React from "react";

import Appointment from "..";
import AppointmentContextProvider from "../context";

import { AppointmentLayoutProps } from "./types";

const AppointmentLayout = (props: AppointmentLayoutProps) => {
  const {
    expertId,
    isSuccess,
    isFailure,
    sessionType,
    redirectToPaymentFlag,
    isTrialAllowed,
  } = props;

  return (
    <AppointmentContextProvider>
      <Appointment
        expertId={expertId}
        isSuccess={isSuccess}
        isFailure={isFailure}
        sessionType={sessionType}
        redirectToPaymentFlag={redirectToPaymentFlag}
        isTrialAllowed={isTrialAllowed}
      />
    </AppointmentContextProvider>
  );
};

export default AppointmentLayout;
