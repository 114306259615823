import React from "react";

import { SessionType } from "@/autoGeneratedGlobalTypes";
import { Icon, IconSizeEnum, IconTypeEnum } from "@components/common/icon";

import { AppointmentStatusEnum } from "../types";
import { isSessionReadyToBeCreated } from "../utils";

import { SessionProcessBarProps } from "./types";
import "./styles.scss";

const SessionProcessBar = ({ sessionType, status }: SessionProcessBarProps) => {
  const isSessionPayed = isSessionReadyToBeCreated(status)
    || status === AppointmentStatusEnum.PaymentSuccess;

  return (
    <div className="session-process-bar">
      <div className="session-process-bar__item done">
        <div className="session-process-bar__bullet">
          <Icon type={IconTypeEnum.Tick} size={IconSizeEnum.Size16} />
        </div>
        <div className="session-process-bar__text">Выбор эксперта</div>
      </div>
      {status !== AppointmentStatusEnum.PaymentSkipped && (
        <div className={`session-process-bar__item ${isSessionPayed
          ? "done" : "in-progress"
        }`}
        >
          <div className="session-process-bar__bullet">
            {isSessionPayed
              ? <Icon type={IconTypeEnum.Tick} size={IconSizeEnum.Size16} />
              : <div className="session-process-bar__circle" />}
          </div>
          <div className="session-process-bar__text">Оплата</div>
        </div>
      )}
      {sessionType === SessionType.VIDEO_CALL && (
        <div className="session-process-bar__item coming">
          <div className="session-process-bar__bullet">
            <div className="session-process-bar__circle" />
          </div>
          <div className="session-process-bar__text">Проверка связи</div>
        </div>
      )}
      {sessionType === SessionType.PHONE_CALL && (
        <div className={`session-process-bar__item ${isSessionReadyToBeCreated(status) ? "in-progress" : "coming"}`}>
          <div className="session-process-bar__bullet">
            <div className="session-process-bar__circle" />
          </div>
          <div className="session-process-bar__text">Подготовка звонка</div>
        </div>
      )}
      <div className="session-process-bar__item coming">
        <div className="session-process-bar__bullet">
          <div className="session-process-bar__circle" />
        </div>
        <div className="session-process-bar__text">Консультация</div>
      </div>
    </div>
  );
};

export default SessionProcessBar;
