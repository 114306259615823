import React, {
  useCallback, useContext, useEffect, useState,
} from "react";

import { useLazyQuery } from "@apollo/client";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";
import { Helmet } from "react-helmet";

import { Currency, PaymentLocation, PaymentProvider } from "@/autoGeneratedGlobalTypes";
import {
  Button,
  ButtonColorEnum,
  ButtonIconPositionEnum,
  ButtonSizeEnum,
} from "@/components/common/button";
import { Dropdown, DropdownSizeEnum, DropdownValue } from "@/components/common/dropdown";
import ExchangeRate from "@/components/common/exchangeRate";
import { Icon, IconSizeEnum, IconTypeEnum } from "@/components/common/icon";
import PaymentAmountPicker from "@/components/common/PaymentAmountPicker";
import { MIN_PAYMENT_AMOUNT, paymentTexts } from "@/components/constants";
import { MIN_SESSION_DURATION } from "@/constants";
import { UserContextType } from "@/contexts/User/types";
import UserContext from "@/contexts/User/UserContext";
import { usePaymentForm } from "@/hooks/usePaymentForm";
import { setUnfinishedPaymentIdFromBankRedirect } from "@/hooks/usePaymentForm/utils";
import { PaymentSuccessDataType, usePayWithSavedCard } from "@/hooks/usePayWithSavedCard";
import { MARKETING_PAYMENT_SUCCESS } from "@/marketing/constants";
import { isBrowser } from "@/utils/env";
import { currencyToString } from "@/utils/globalTypesUtils";
import { getAvailableMinutes } from "@/utils/moneyUtils";
import { priceToString } from "@/utils/numberUtils";
import { cleanupFromTags, declenateWord } from "@/utils/stringUtils";

import AppointmentContext from "../context/AppointmentContext";
import { AppointmentContextType } from "../context/types";
import {
  getExchangeRate as getExchangeRateType,
  getExchangeRateVariables,
} from "../graphql/__generated__/getExchangeRate";
import { GET_EXCHANGE_RATE } from "../graphql/GET_EXCHANGE_RATE";
import { AppointmentStatusEnum } from "../types";
import { areMoneyAndFreeMinutesEnoughForCall, getAvailableMinutesForEnteredAmount } from "../utils";

import {
  APPOINTMENT_PAYMENT_COMMON_INITIAL_AMOUNT,
  CLOUD_PAYMENT_CARD_VALUE,
  MAX_DIGITS_AMOUNT,
  SOFTLINE_PAYMENT_CARD_VALUE,
  SOM_PAYMENT_CARD_VALUE,
} from "./constants";
import { PaymentProps } from "./types";
import {
  cardToJsx, getBonusFreeMinutesDiscount, getMinimumTopUpAmount,
  getPaymentDescription, getTextFreeMinutes, isAmountValid,
} from "./utils";

import "./styles.scss";

const PaymentForm = ({
  sessionType,
  userCards,
  balance,
  freeMinutesCount,
  callRate,
  expertId,
  isTrialAllowed,
}: PaymentProps) => {
  const {
    appointmentStatus, setAppointmentStatus, setAppointmentPaymentId,
  } = useContext<AppointmentContextType>(AppointmentContext);
  const isDiscountPromoActive = process.env.GATSBY_IS_DISCOUNT_PROMO_ACTIVE === "true";
  const { isUserLoggedIn, setUnfinishedPaymentId } = useContext<UserContextType>(UserContext);

  const [isBackgroundEmpty, setIsBackgroundEmpty] = useState<boolean>(false);
  const [isAmountTouched, setIsAmountTouched] = useState<boolean>(false);
  const [selectedAmount, setSelectedAmount] = useState<number>(
    APPOINTMENT_PAYMENT_COMMON_INITIAL_AMOUNT,
  );
  const [cards, setCards] = useState<DropdownValue[]>([]);
  const [selectedCard, setSelectedCard] = useState<DropdownValue>(CLOUD_PAYMENT_CARD_VALUE);
  const [rememberCard, setRememberCard] = useState<boolean>(true);
  const redirectPathAfterPayment = useLocation().href;
  const [loadingForForeignPayment, setLoadingForForeignPayment] = useState(false);

  const isSelectedSomPayment = selectedCard.value === SOM_PAYMENT_CARD_VALUE.value;
  const isSelectedSoftlinePayment = selectedCard.value === SOFTLINE_PAYMENT_CARD_VALUE.value;
  const isSelectedCloudPayemnt = selectedCard.value === CLOUD_PAYMENT_CARD_VALUE.value;

  const [getExchangeRateQuery, { data: dataExchangeRate }] = useLazyQuery<
    getExchangeRateType,
    getExchangeRateVariables
  >(GET_EXCHANGE_RATE);

  useEffect(() => {
    if (isSelectedSomPayment || isSelectedSoftlinePayment) {
      const paymentProviderType = isSelectedSomPayment
        ? PaymentProvider.SomPayments : PaymentProvider.SoftlinePayments;

      getExchangeRateQuery({
        variables: {
          input: {
            curFrom: isSelectedSomPayment ? Currency.USD : Currency.EUR,
            curTo: Currency.RUB,
            paymentProvider: paymentProviderType,
          },
        },
      });
    }
  }, [getExchangeRateQuery, isSelectedSoftlinePayment, isSelectedSomPayment, selectedCard]);

  const paymentSuccessCallback = useCallback(() => {
    // When paying with new card payment provider shows paymen result,
    // so there is no need to do this ourselves as we do with payment by token
    setAppointmentStatus(AppointmentStatusEnum.SessionStart);
    setAppointmentPaymentId(null);
    setIsBackgroundEmpty(false);
  }, [setAppointmentPaymentId, setAppointmentStatus]);

  const paymentFailCallback = useCallback(() => {
    // When payingg with new card payment provider shows paymen result,
    // so there is no need to do this ourselves as we do with payment by token
    setAppointmentStatus(
      areMoneyAndFreeMinutesEnoughForCall(
        balance?.amount ?? null,
        callRate,
        freeMinutesCount ?? 0,
        sessionType,
        isTrialAllowed,
      )
        ? AppointmentStatusEnum.StartOrPayChoice
        : AppointmentStatusEnum.Payment,
    );
    setAppointmentPaymentId(null);
    setIsBackgroundEmpty(false);
  }, [balance?.amount, callRate, freeMinutesCount, isTrialAllowed,
    sessionType, setAppointmentPaymentId, setAppointmentStatus]);

  useEffect(() => {
    // результат после оплаты иностранной картой
    if (redirectPathAfterPayment.includes("request=success")) {
      setUnfinishedPaymentId(setUnfinishedPaymentIdFromBankRedirect(redirectPathAfterPayment));
      paymentSuccessCallback();
    }

    if (redirectPathAfterPayment.includes("request=fail")) {
      setUnfinishedPaymentId(setUnfinishedPaymentIdFromBankRedirect(redirectPathAfterPayment));
      paymentFailCallback();
    }
  }, [redirectPathAfterPayment, paymentFailCallback,
    paymentSuccessCallback, setUnfinishedPaymentId]);

  const { openPaymentForm } = usePaymentForm({
    onSuccess: paymentSuccessCallback,
    onFail: paymentFailCallback,
  });

  const paymentWithSavedCardSuccessCallback = useCallback(
    (data: PaymentSuccessDataType) => {
      // todo for phone calls: update URL with MARKETING_PAYMENT_SUCCESS
      // todo for phone calls: maybe do this in paymentWithSavedCardCompleteCallback
      window.history.replaceState(
        {},
        "",
        `${window.location.pathname}${
          window.location.search
            ? `${window.location.search}&${MARKETING_PAYMENT_SUCCESS}=1`
            : `?${MARKETING_PAYMENT_SUCCESS}=1`
        }`,
      );
      setAppointmentStatus(AppointmentStatusEnum.PaymentSuccess);
      setAppointmentPaymentId(data.makePaymentWithSavedCard.id);
    },
    [setAppointmentPaymentId, setAppointmentStatus],
  );

  const paymentWithSavedCardFailCallback = useCallback(
    (data: PaymentSuccessDataType) => {
      setAppointmentStatus(AppointmentStatusEnum.PaymentFailed);
      setAppointmentPaymentId(data.makePaymentWithSavedCard.id);
      // navigate(
      //   `/appointment/?failure=1&payment_id=${
      //     data.makePaymentWithSavedCard.id
      //   }&expert_id=${appointedExpertId}`,
      // );
    },
    [setAppointmentPaymentId, setAppointmentStatus],
  );

  const { payWithSavedCard, loading: payWithSavedCardLoading } = usePayWithSavedCard({
    onSuccess: paymentWithSavedCardSuccessCallback,
    onFail: paymentWithSavedCardFailCallback,
  });

  useEffect(() => {
    const joinSoftLinePaymentFlag = process.env.GATSBY_ADD_SOFTLINE_PAYMENT === "true";
    if (userCards) {
      const convertedUserCards: DropdownValue[] = userCards?.map((c) =>
        cardToJsx(c)) ?? [];
      convertedUserCards.push(CLOUD_PAYMENT_CARD_VALUE, SOM_PAYMENT_CARD_VALUE);

      if (joinSoftLinePaymentFlag) {
        convertedUserCards.push(SOFTLINE_PAYMENT_CARD_VALUE);
      }
      setCards(convertedUserCards);
      const defaultCard = userCards.find((card) =>
        card.isDefault);
      if (defaultCard) {
        setSelectedCard(cardToJsx(defaultCard));
      } else {
        setSelectedCard(CLOUD_PAYMENT_CARD_VALUE);
      }
    }
  }, [userCards]);

  if (!isUserLoggedIn) {
    if (isBrowser()) {
      navigate("/");
    }
  }

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const numberValue = parseInt(e.target.value, 10);
    // user may enter zeros before the value, and we preserve them,
    // but we make sure the value is valid
    setSelectedAmount((oldValue) =>
      (numberValue.toString().length <= MAX_DIGITS_AMOUNT ? numberValue : oldValue));
  };

  const handleCardChange = (value: DropdownValue) => {
    setSelectedCard(value);
    if (value.value === CLOUD_PAYMENT_CARD_VALUE.value) {
      setTimeout(() => {
        // todo: make scrolling to bottom
      }, 1000);
    }
  };

  const handleBulletClick = (amount: number) => {
    setSelectedAmount(amount);
  };

  const handleStartSessionClick = () => {
    setAppointmentStatus(AppointmentStatusEnum.SessionStart);
  };

  const handlePayClick = () => {
    switch (true) {
      case isSelectedCloudPayemnt:
        setIsBackgroundEmpty(true);
        openPaymentForm(
          selectedAmount,
          rememberCard,
          PaymentProvider.CloudPayments,
          "",
          PaymentLocation.appointment,
          expertId,
        );
        break;
      case isSelectedSomPayment:
        setLoadingForForeignPayment(true);
        openPaymentForm(
          selectedAmount,
          rememberCard,
          PaymentProvider.SomPayments,
          redirectPathAfterPayment,
          PaymentLocation.appointment,
          expertId,
        );
        break;
      case isSelectedSoftlinePayment:
        setLoadingForForeignPayment(true);
        openPaymentForm(
          selectedAmount,
          rememberCard,
          PaymentProvider.SoftlinePayments,
          redirectPathAfterPayment,
          PaymentLocation.appointment,
          expertId,
        );
        break;

      default:
        payWithSavedCard(selectedAmount, Number(selectedCard.value), PaymentLocation.appointment, expertId);
    }
  };

  const availableMinutes = getAvailableMinutes(balance.amount, callRate);
  const isPaymentFormShown = appointmentStatus === AppointmentStatusEnum.Payment;

  const isBonusAmount = getBonusFreeMinutesDiscount(selectedAmount) !== 0;

  return (
    <>
      <Helmet>
        <body className="footer--short header--short" />
      </Helmet>
      <div style={{ display: isBackgroundEmpty ? "none" : "block" }}>
        <div className="payment__container">
          <div className="payment">
            {isPaymentFormShown && <h2 className="payment__header">Пополните баланс</h2>}
            <div className="payment__description">
              {getPaymentDescription(balance.amount, callRate, freeMinutesCount, isTrialAllowed)}
            </div>

            <div className="payment__card">
              <div>
                <div className="payment__card-label">Ваш баланс</div>
                <h4 className="payment__balance">
                  <span
                    className="payment__amount"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: cleanupFromTags(priceToString(balance.amount).replace(" ", "&nbsp;")),
                    }}
                  />
                  &nbsp;
                  <span className="payment__currency">{currencyToString(balance.currency)}</span>
                </h4>
              </div>
              <div>
                {isPaymentFormShown && !!availableMinutes && (
                  <div className="payment__enough-for">
                    Хватит на
                    {" "}
                    {availableMinutes}
                    {" "}
                    {declenateWord(availableMinutes, ["минуту", "минуты", "минут"])}
                  </div>
                )}
                {!isPaymentFormShown && (
                  <Button
                    text="Пополнить"
                    subtext={selectedAmount ? `${priceToString(selectedAmount)} ₽` : ""}
                    size={ButtonSizeEnum.XSmall}
                    color={ButtonColorEnum.Dark}
                    onClick={() => {
                      setAppointmentStatus(AppointmentStatusEnum.Payment);
                    }}
                  />
                )}
              </div>
            </div>

            {isPaymentFormShown && (
              <form
                className="payment__form"
                onSubmit={(e) =>
                  e.preventDefault()}
              >
                <div className="payment__field-groups">
                  <div className="payment__field-group">
                    <div className="payment__label">
                      <label htmlFor="amount">Сумма пополнения</label>
                    </div>
                    <input
                      id="amount"
                      name="amount"
                      type="number"
                      pattern="\d*"
                      inputMode="numeric"
                      value={Number.isNaN(selectedAmount) ? "" : selectedAmount}
                      onChange={handleAmountChange}
                      onBlur={() =>
                        setIsAmountTouched(true)}
                      className={`payment__input amount${
                        isAmountTouched
                        && !isAmountValid(
                          selectedAmount,
                          balance.amount,
                          callRate,
                          freeMinutesCount,
                        )
                          ? " error"
                          : ""
                      }`}
                      aria-invalid={
                        isAmountTouched
                        && !isAmountValid(
                          selectedAmount,
                          balance.amount,
                          callRate,
                          freeMinutesCount,
                        )
                          ? "true"
                          : "false"
                      }
                    />
                    {isAmountValid(selectedAmount, balance.amount, callRate, freeMinutesCount) && (
                      <div className="payment__minutes-text" role="alert">
                        {`Хватит на ${getAvailableMinutesForEnteredAmount(selectedAmount, callRate)}
                        ${declenateWord(getAvailableMinutesForEnteredAmount(selectedAmount, callRate), ["минуту", "минуты", "минут"])}
                        разговора${isTrialAllowed ? getTextFreeMinutes(freeMinutesCount, isDiscountPromoActive, selectedAmount) : ""}`}
                      </div>
                    )}
                    {isAmountTouched
                      && !isAmountValid(
                        selectedAmount,
                        balance.amount,
                        callRate,
                        freeMinutesCount,
                      ) && (
                        <div className="payment__error" role="alert">
                          {`Минимальная сумма пополнения — ${getMinimumTopUpAmount(isTrialAllowed, freeMinutesCount, callRate, balance.amount)} ₽`}
                        </div>
                    )}
                  </div>
                  <PaymentAmountPicker
                    rate={callRate}
                    handleBulletClick={handleBulletClick}
                    isBonusAmount={isBonusAmount}
                  />
                  <div className="payment__field-group">
                    <div className={`payment__label ${isDiscountPromoActive && "payment__action-label"}`}>
                      <label htmlFor="card">Способ пополнения</label>
                    </div>
                    <Dropdown
                      id="card"
                      name="card"
                      value={selectedCard}
                      options={cards}
                      onChange={handleCardChange}
                      size={DropdownSizeEnum.Middle}
                      className="payment__input"
                    />
                    {(isSelectedSomPayment || isSelectedSoftlinePayment)
                    && dataExchangeRate && selectedAmount
                    && (
                    <ExchangeRate
                      amount={selectedAmount}
                      paymentEUR={isSelectedSoftlinePayment}
                      paymentUSD={isSelectedSomPayment}
                      rate={dataExchangeRate?.getExchangeRate}
                    />
                    )}
                  </div>
                  <div className="payment__sticky">
                    <Button
                      text="Пополнить"
                      subtext={selectedAmount ? `на ${priceToString(selectedAmount)} ₽` : ""}
                      size={ButtonSizeEnum.Large}
                      color={ButtonColorEnum.Dark}
                      className="payment__submit"
                      isLoading={payWithSavedCardLoading || loadingForForeignPayment}
                      disabled={
                        !isAmountValid(
                          selectedAmount,
                          balance.amount,
                          callRate,
                          freeMinutesCount,
                        )
                        || getAvailableMinutes(balance.amount, callRate, true)
                          + getAvailableMinutesForEnteredAmount(selectedAmount, callRate, true)
                          < (MIN_SESSION_DURATION - freeMinutesCount)
                      }
                      onClick={handlePayClick}
                    />
                  </div>
                  {(isSelectedCloudPayemnt
                    || isSelectedSomPayment
                    || isSelectedSoftlinePayment) && (
                    <div className="payment__remember-block">
                      <div className="payment__checkbox-group">
                        <input
                          type="checkbox"
                          checked={rememberCard}
                          onChange={() =>
                            setRememberCard((prevVal) =>
                              !prevVal)}
                        />
                        <span className="payment__checkbox-label">{paymentTexts.rememberText}</span>
                      </div>
                      <div className="payment__message">{paymentTexts.message}</div>
                      <div className="payment__message">
                        <Icon type={IconTypeEnum.Shield} size={IconSizeEnum.Size28} />
                        {paymentTexts.protectionInfo}
                      </div>
                    </div>
                  )}
                </div>
              </form>
            )}
            {!isPaymentFormShown && (
              <div className="payment__sticky">
                <Button
                  text="Начать консультацию"
                  size={ButtonSizeEnum.Large}
                  color={ButtonColorEnum.Dark}
                  icon={IconTypeEnum.Right}
                  iconPosition={ButtonIconPositionEnum.Right}
                  className="payment__submit"
                  onClick={handleStartSessionClick}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentForm;
