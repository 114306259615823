import React, { useContext, useEffect, useState } from "react";

import { useLazyQuery, useMutation } from "@apollo/client";
import { navigate } from "gatsby";

import { CardType, PaymentError } from "@/autoGeneratedGlobalTypes";
import {
  Button,
  ButtonColorEnum,
  ButtonIconPositionEnum,
  ButtonSizeEnum,
} from "@/components/common/button";
import Confetti from "@/components/common/confetti";
import { Icon, IconSizeEnum, IconTypeEnum } from "@/components/common/icon";
import Loader from "@/components/common/loader";
import Support from "@/components/common/support";
import { UserContextType } from "@/contexts/User/types";
import UserContext from "@/contexts/User/UserContext";
import { convertToDate, convertToTime } from "@/utils/dateUtils";
import { isBrowser } from "@/utils/env";
import { cardTypeToString, currencyToString } from "@/utils/globalTypesUtils";
import { priceToString } from "@/utils/numberUtils";

import AppointmentContext from "../context/AppointmentContext";
import { AppointmentContextType } from "../context/types";
import { AppointmentStatusEnum } from "../types";

import { getPayment as getPaymentType, getPaymentVariables } from "./graphql/__generated__/getPayment";
import {
  setDefaultCardAfterPayment,
  setDefaultCardAfterPaymentVariables,
} from "./graphql/__generated__/setDefaultCardAfterPayment";
import { GET_PAYMENT } from "./graphql/GET_PAYMENT";
import { SET_DEFAULT_CARD } from "./graphql/SET_DEFAULT_CARD";
import { PaymentResultProps } from "./types";

import "./styles.scss";

const PaymentResult = ({ handleButtonClick }: PaymentResultProps) => {
  const {
    appointmentStatus, appointmentPaymentId,
  } = useContext<AppointmentContextType>(AppointmentContext);
  const { isUserLoggedIn } = useContext<UserContextType>(UserContext);

  const [getPayment, {
    data: getPaymentData,
    error: getPaymentError,
    loading: getPaymentLoading,
  }] = useLazyQuery<
    getPaymentType,
    getPaymentVariables
  >(GET_PAYMENT);

  const [setDefaultCard] = useMutation<
    setDefaultCardAfterPayment,
    setDefaultCardAfterPaymentVariables
  >(SET_DEFAULT_CARD, {
    errorPolicy: "all", // todo: make general behaviour for network errors
  });

  const [areDetailsOpened, setAreDetailsOpened] = useState<boolean>(false);

  useEffect(() => {
    if (!isUserLoggedIn && isBrowser()) {
      navigate("/");
    }
    if (appointmentPaymentId) {
      getPayment({ variables: { paymentID: appointmentPaymentId } });
    }
  }, [getPayment, isUserLoggedIn, appointmentPaymentId]);

  useEffect(() => {
    if (appointmentStatus === AppointmentStatusEnum.PaymentSuccess
      && getPaymentData?.getPayment?.card
      && !getPaymentError
      && !getPaymentLoading) {
      setDefaultCard({ variables: { cardID: getPaymentData.getPayment.card.id } });
    }
  }, [
    getPaymentData,
    getPaymentError,
    getPaymentLoading,
    appointmentStatus,
    setDefaultCard,
  ]);

  if (getPaymentLoading) {
    return <Loader />;
  }

  const handleDetailsClick = () => {
    setAreDetailsOpened((oldVal) =>
      !oldVal);
  };

  const {
    amount: {
      amount,
      currency,
    },
    errorValue,
    datetime,
    card,
  } = getPaymentData?.getPayment ?? {
    amount: {},
    card: {
      type: undefined,
      number: undefined,
    },
  };

  const isSuccess = appointmentStatus === AppointmentStatusEnum.PaymentSuccess;
  const isFailure = appointmentStatus === AppointmentStatusEnum.PaymentFailed;
  const showSuccessForm = isSuccess && currency;

  return (
    <>
      {showSuccessForm && <Confetti />}
      <div className="payment-result__container">
        <div className="payment-result">
          <div className="payment-result__middle">
            {isSuccess && currency && (
            <>
              <div className="payment-result__success-circle">
                <Icon type={IconTypeEnum.Tick} size={IconSizeEnum.Size24} />
              </div>
              <h3 className="payment-result__success-header">
                Ваш баланс пополнен
                {" "}
                {amount ? `на ${priceToString(amount)} ${currencyToString(currency)}\n` : ""}
              </h3>
            </>
            )}
            {isFailure && currency && (
            <>
              <div className="payment-result__failure-circle">
                <Icon type={IconTypeEnum.Exclamation} size={IconSizeEnum.Size24} />
              </div>
              <h3 className="payment-result__failure-header">
                Платёж
                {" "}
                {amount ? `на ${priceToString(amount)} ${currencyToString(currency)}\n` : ""}
                отклонён
              </h3>

              {errorValue === PaymentError.InsufficientFundsOnBalance
                && (
                  <div className="payment-result__text">
                    Недостаточно средств. Пополните карту
                    <br />
                    или воспользуйтесь другой.
                  </div>
                )}

              {errorValue === PaymentError.CommonError && (
              <div className="payment-result__text">Произошла ошибка</div>
              )}
            </>
            )}
            <div
              className={areDetailsOpened
                ? "payment-result__details is-opened"
                : "payment-result__details"}
              onClick={handleDetailsClick}
            >
              <span>Детали</span>
              {areDetailsOpened && (
              <div className="payment-result__table">
                {card && (
                <div className="payment-result__row">
                  <div className="payment-result__column">
                    <label>
                      <Icon type={IconTypeEnum.Card} size={IconSizeEnum.Size20} />
                      <span>Карта</span>
                    </label>
                  </div>
                  <div className="payment-result__column">
                    <span>
                      {cardTypeToString(card.type ?? CardType.Unknown)}
                      {" "}
                      •
                      {card.number}
                    </span>
                  </div>
                </div>
                )}
                <div className="payment-result__row">
                  <div className="payment-result__column">
                    <label>
                      <Icon type={IconTypeEnum.Number} size={IconSizeEnum.Size20} />
                      <span>Платёж</span>
                    </label>
                  </div>
                  <div className="payment-result__column">
                    <span>{appointmentPaymentId}</span>
                  </div>
                </div>
                {datetime && (
                <div className="payment-result__row">
                  <div className="payment-result__column">
                    <label>
                      <Icon type={IconTypeEnum.Time} size={IconSizeEnum.Size20} />
                      <span>Дата</span>
                    </label>
                  </div>
                  <div className="payment-result__column">
                    <span>
                      {convertToTime(datetime)}
                      {" "}
                      {convertToDate(datetime)}
                    </span>
                  </div>
                </div>
                )}
              </div>
              )}
            </div>
          </div>
          <div className="payment-result__bottom">
            <Button
              className="payment-result__button"
              text={isFailure ? "Вернуться к оплате" : "Начать консультацию"}
              icon={isFailure ? undefined : IconTypeEnum.Right}
              iconPosition={isFailure ? undefined : ButtonIconPositionEnum.Right}
              size={ButtonSizeEnum.Large}
              color={ButtonColorEnum.Dark}
              onClick={handleButtonClick}
            />
            {isFailure && (
            <Support isShort />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentResult;
