import React from "react";

const PhoneNeeded = () =>
  (
    <>
      Эксперт не сможет перезвонить,
      <br />
      не зная ваш телефон
      <br />
      <br />
      Укажите номер телефона в Личном кабинете
    </>
  );

export default PhoneNeeded;
