import React from "react";

import { Icon, IconSizeEnum, IconTypeEnum } from "@/components/common/icon";
import { Tooltip, TooltipPositionEnum } from "@/components/common/tooltip";

import { OUR_PHONE } from "./constants";
import "./styles.scss";

const VoicemailDetected = () =>
  (
    <>
      Трубку взял автоответчик,
      <br />
      или звонок сбросил антиспам
      <br />
      <br />
      Добавьте
      {" "}
      <Tooltip text="Скопировано" position={TooltipPositionEnum.Top}>
        <div
          className="a voicemail-error__copy"
          onClick={() => {
            navigator.clipboard.writeText(OUR_PHONE);
          }}
        >
          <div>{OUR_PHONE}</div>
          <Icon type={IconTypeEnum.Copy} size={IconSizeEnum.Size20} />
        </div>
      </Tooltip>
      {" "}
      в&nbsp;свои контакты
      <br />
      и отправьте заявку повторно
    </>
  );

export default VoicemailDetected;
