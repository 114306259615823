import React from "react";

import { SessionEndReason } from "@/autoGeneratedGlobalTypes";
import { IconTypeEnum } from "@components/common/icon";

import NonValidPhone from "./phoneSessionErrors/nonValidPhone";
import PhoneNeeded from "./phoneSessionErrors/phoneNeeded";
import UnknownError from "./phoneSessionErrors/unknownError";
import VoicemailDetected from "./phoneSessionErrors/voicemailDetected";

const defaultData = {
  topIcon: IconTypeEnum.Tick,
  title: "Приняли заявку",
  buttonText: "",
};

export const PHONE_SESSION_ERRORS: { [key: string | number]: any } = {
  31: {
    ...defaultData,
    topIcon: IconTypeEnum.Exclamation,
    title: "Не распознали номер",
    description: <NonValidPhone />,
    buttonText: "Понятно",
  },
  24: {
    ...defaultData,
    topIcon: IconTypeEnum.Exclamation,
    title: "Нужен номер телефона",
    description: <PhoneNeeded />,
    buttonText: "Понятно",
  },
  6: {
    ...defaultData,
    topIcon: IconTypeEnum.Call,
    title: "Консультация уже идёт",
    buttonText: "Попробовать ещё раз",
    buttonIcon: IconTypeEnum.Reload,
  },
  [SessionEndReason.voice_mail_detected]: {
    ...defaultData,
    topIcon: IconTypeEnum.Exclamation,
    title: "Мы не дозвонились",
    description: <VoicemailDetected />,
    buttonText: "Повторить заявку",
  },
  unknownError: {
    ...defaultData,
    topIcon: IconTypeEnum.Exclamation,
    title: "Ошибка соединения",
    description: <UnknownError />,
    buttonText: "Понятно",
  },
  default: {
    ...defaultData,
  },
};
