import React from "react";

const NonValidPhone = () =>
  (
    <>
      Похоже, телефон из другого региона,
      <br />
      или в номере есть ошибка
      <br />
      <br />
      Попробуйте позвонить эксперту по видео
    </>
  );

export default NonValidPhone;
