import React, {
  useCallback, useContext, useEffect, useState,
} from "react";

import { useQuery } from "@apollo/client";
import { Link, navigate } from "gatsby";

import { SessionEndReason } from "@/autoGeneratedGlobalTypes";
import { Button, ButtonIconPositionEnum, ButtonSizeEnum } from "@/components/common/button";
import { Icon, IconSizeEnum } from "@/components/common/icon";
import Loader from "@/components/common/loader";
import Support from "@/components/common/support";
import { UserContextType } from "@/contexts/User/types";
import UserContext from "@/contexts/User/UserContext";
import usePhoneCall from "@/hooks/usePhoneCall";

import { getPhoneSessionPageData } from "./graphql/__generated__/getPhoneSessionPageData";
import { GET_PHONE_SESSION_PAGE_DATA } from "./graphql/GET_PHONE_SESSION_PAGE_DATA";
import { PhoneSessionProps } from "./types";
import { getErrorDisplayData } from "./utils";
import "./styles.scss";

const PhoneSession = (props: PhoneSessionProps) => {
  const {
    expertId,
  } = props;
  const {
    phone,
    setPhone,
  } = useContext<UserContextType>(UserContext);

  const [isLoading, setIsLoading] = useState<boolean>();

  const { data, error, loading } = useQuery<getPhoneSessionPageData>(GET_PHONE_SESSION_PAGE_DATA, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (data && !error && !loading) {
      if (data.getMyProfile.phone) {
        setPhone(data.getMyProfile.phone);
      }
    }
  }, [data, error, loading, setPhone]);

  const [
    errorCodeList,
    setErrorCodeList,
  ] = useState<(number | SessionEndReason)[] | null>(null);
  const [pageData, setPageData] = useState(() =>
    getErrorDisplayData());

  const createPhoneCallSuccessCallback = useCallback(() => {
    setErrorCodeList(null);
    setIsLoading(false);
  }, []);

  const createPhoneCallFailCallback = useCallback((errorList: number[]) => {
    setErrorCodeList(errorList);
    setIsLoading(false);
  }, []);

  const phoneCallFailCallback = useCallback((endReasonList?: SessionEndReason[]) => {
    if (!endReasonList) {
      setErrorCodeList([]); // пустой массив означает неизвестную ошибку
    } else {
      setErrorCodeList(endReasonList);
    }
    setIsLoading(false);
  }, []);

  const { createPhoneCall } = usePhoneCall(
    createPhoneCallSuccessCallback,
    createPhoneCallFailCallback,
    phoneCallFailCallback,
  );

  useEffect(() => {
    createPhoneCall(expertId);
    setIsLoading(true);
  }, [createPhoneCall, expertId]);

  useEffect(() => {
    setPageData(() =>
      getErrorDisplayData(errorCodeList));
  }, [errorCodeList]);

  const buttonClickHandler = () => {
    if (errorCodeList) {
      if (errorCodeList.includes(6)) {
        createPhoneCall(expertId);
        setIsLoading(true);
      } else {
        navigate(-1);
      }
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  // todo: uncomment if we allow free trial for phone calls
  // const isFFAllowFirstSessionWithoutPaymentOn =
  // process.env.GATSBY_ALLOW_FIRST_PHONE_SESSION_WITHOUT_PAYMENT === "true";

  return (
    <div>
      <div className="phone-session__container">
        <div className="phone-session">
          <div className={`phone-session__circle-icon ${!errorCodeList ? "green" : "red"}`}>
            <Icon type={pageData.topIcon} size={IconSizeEnum.Size28} />
          </div>
          <h2 className="phone-session__header">{pageData.title}</h2>
          {!errorCodeList && (
            <>
              <div className="phone-session__text">
                В течение минуты эксперт позвонит вам
                <br />
                на номер
                {" "}
                {phone && (
                  <span className="phone-session__phone">
                    {phone}
                  </span>
                )}
              </div>
              {/* todo: uncomment if we allow free trial for phone calls
              {!isFFAllowFirstSessionWithoutAnyPaymentOn && !!freeMinutesCount && (
                <div className="phone-session__gift">
                  <Icon type={IconTypeEnum.Gift} size={IconSizeEnum.Size20} />
                  {freeMinutesCount}
                  {" "}
                  мин в подарок
                </div>
              )}
              {isFFAllowFirstSessionWithoutAnyPaymentOn
                && appointmentStatus === AppointmentStatusEnum.PaymentSkipped
                && (
                <>
                  <div className="phone-session__text">
                    Продлится
                    {" "}
                    {freeMinutesCount}
                    {" "}
                    мин, затем звонок завершится
                  </div>
                </>
                )} */}
              <div className="phone-session__text">
                Разговор можно продлить:
                <br />
                <Link to="/profile/payment">пополните баланс в&nbsp;Личном кабинете</Link>
                <br />
                и&nbsp;снова позвоните эксперту
              </div>
            </>
          )}
          {!!errorCodeList && (
            <>
              {pageData.description
                && <div className="phone-session__text">{pageData.description}</div>}
              <Button
                text={pageData.buttonText}
                size={ButtonSizeEnum.Large}
                icon={pageData.buttonIcon}
                className="phone-session__button"
                iconPosition={pageData.buttonIcon ? ButtonIconPositionEnum.Left : undefined}
                onClick={buttonClickHandler}
              />
            </>
          )}
        </div>
        <div className="phone-session__support">
          <Support />
        </div>
      </div>
    </div>
  );
};

export default PhoneSession;
