import { SessionEndReason } from "@/autoGeneratedGlobalTypes";
import { PhoneSessionPageDataProps } from "@components/appointment/phoneSession/types";

import { PHONE_SESSION_ERRORS } from "./constants";

function getErrorDisplayDataByCode(errorCode?: number | SessionEndReason | string) {
  if (errorCode) {
    return PHONE_SESSION_ERRORS[errorCode]
      ? PHONE_SESSION_ERRORS[errorCode] : PHONE_SESSION_ERRORS.unknownError;
  }

  return PHONE_SESSION_ERRORS.default;
}

export function getErrorDisplayData(errorCodeList?: (number | SessionEndReason)[] | null)
  : PhoneSessionPageDataProps {
  if (errorCodeList) {
    switch (true) {
      case errorCodeList.includes(31): // StatusCodePhoneNotSupported
        return getErrorDisplayDataByCode(31);
      case errorCodeList.includes(24): // StatusCodeInvalidPhone
        return getErrorDisplayDataByCode(24);
      case errorCodeList.includes(6): // StatusCodeExpertIsUnavailable
        return getErrorDisplayDataByCode(6);
      case errorCodeList.includes(SessionEndReason.voice_mail_detected):
        return getErrorDisplayDataByCode(SessionEndReason.voice_mail_detected);
      default:
        return getErrorDisplayDataByCode("unknownError");
    }
  }

  return getErrorDisplayDataByCode();
}
