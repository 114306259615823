import { SessionType } from "@/autoGeneratedGlobalTypes";

export enum AppointmentStatusEnum {
  PaymentSkipped = "PaymentSkipped",
  StartOrPayChoice = "StartOrPayChoice",
  Payment = "Payment",
  PaymentSuccess = "PaymentSuccess",
  PaymentFailed = "PaymentFailed",
  SessionStart = "SessionStart",
}

export type AppointmentProps = {
  expertId: number;
  isSuccess?: boolean;
  isFailure?: boolean;
  sessionType: SessionType;
  redirectToPaymentFlag: boolean | undefined
  isTrialAllowed: boolean
};
