import { gql } from "@apollo/client";

// todo: move to common folder
export const GET_SESSION = gql`
  query getSession($sessionID: Int!) {
    getSession(sessionID: $sessionID) {
      id
      status
      expert {
        mobileImage: image(size: 120) {
          onex
          twox
          threex
        }
        desktopImage: image(size: 120) {
          onex
          twox
          threex
        }
        name
        id
        skills {
          badge
        }
        rates {
          videoCall
          phoneCall
          chat
        }
        voxImplantInfo {
          userName
        }
      }
      client {
        id
        mobileImage: image(size: 120) {
          onex
          twox
          threex
        }
        desktopImage: image(size: 120) {
          onex
          twox
          threex
        }
        name
        dateOfBirth
        voxImplantInfo {
          userName
        }
      }
      startTimestamp
      outOfMoneyTime
      statistics {
        durationInMinutes
        totalPrice
      }
      sessionType
      endReason
      voxConversationID
    }
    getMyProfile {
      isExpert
    }
  }
`;
