import React from "react";

import { DropdownValue } from "@/components/common/dropdown";
import { Icon, IconSizeEnum, IconTypeEnum } from "@/components/common/icon";

export const CLOUD_PAYMENT_CARD_VALUE: DropdownValue = {
  value: "-1",
  label: (
    <label className="payment__option">
      <Icon type={IconTypeEnum.ProtectedCard} size={IconSizeEnum.Size32} />
      <span>Российской картой (₽)</span>
    </label>
  ),
};

export const SOM_PAYMENT_CARD_VALUE: DropdownValue = {
  value: "-2",
  label: (
    <label className="payment__option">
      <Icon type={IconTypeEnum.ProtectedCard} size={IconSizeEnum.Size32} />
      <span>Иностранной картой ($)</span>
    </label>
  ),
};

export const SOFTLINE_PAYMENT_CARD_VALUE: DropdownValue = {
  value: "-3",
  label: (
    <label className="payment__option">
      <Icon type={IconTypeEnum.ProtectedCard} size={IconSizeEnum.Size32} />
      <span>Иностранной картой (€)</span>
    </label>
  ),
};

export const MAX_DIGITS_AMOUNT = 10;

export const APPOINTMENT_PAYMENT_COMMON_INITIAL_AMOUNT = 1000;
